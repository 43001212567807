import React, { useEffect, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import { LocalStorageServices } from '../../../../../services/localStorageServices';
import VehicleEnquirey from './vehicleEnquirey/VehicleEnquirey';
import PropertyEnquirey from './propertyEnquirey/PropertyEnquirey';

function Enquiries() {
    const navigate = useNavigate()
    const { search } = useLocation();
    const queryParams = new URLSearchParams(search);
    const selected_enquirey_type = queryParams.get("selected_enquirey_type");
    const [selectedLookup, setselectedLookup] = useState(null)
    useEffect(() => {
        if (selected_enquirey_type) {
            setselectedLookup(selected_enquirey_type)
        } else {
            navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/enquires`);
        }
    }, [selected_enquirey_type])
   


   
    return (
        <div>
            <h3 className="page_title">Enquiries</h3>
        
            <PropertyEnquirey/>
        </div>
    )
}



export default Enquiries