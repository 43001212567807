import React, { useState, useEffect } from "react";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import { Autocomplete } from '@mui/material';
import TextField from '@mui/material/TextField';
import "./PropertyTypeForm.css";
import Button from "@mui/material/Button";
import CloudUploadOutlinedIcon from '@mui/icons-material/CloudUploadOutlined';
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from "../../../../../../components/formButton/FormButton";
import { FormHelperText } from "@mui/material";
import { useDispatch } from "react-redux";
import {
  createPropertyType,
  updatePropertyType,
} from "../../../../../../api/apiServices/propertyTypeApi";
import { FaUpload } from "react-icons/fa";
import CancelIcon from "@mui/icons-material/Cancel";
import ResponseHandler from "../../../../../../services/ResponseHandler";
function PropertyTypeForm({ EditDetails, onclose }) {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [isImageSelected, setIsImageSelected] = useState(0);
  useEffect(() => {
    if (EditDetails?.attachment) {
      setSelectedImage(EditDetails.attachment);
    }
  }, [EditDetails]);
  console.log(EditDetails, "EditDetails");
  console.log(selectedImage, "selectedImage");
  const formik = useFormik({
    initialValues: {
      type: "",
      // status: EditDetails?.status === "active" ? 1 : 2,
      status: EditDetails ? (EditDetails.status === "active" ? "active" : "inactive") : "",
      attachment: EditDetails ? EditDetails.attachment : "",
      file: null,
    },
    validationSchema: Yup.object({
      type: Yup.string().required("Property type is required"),
      status: Yup.string().required("Status is required"),
      file: Yup.mixed()
  .nullable()
  .test(
    "requiredFile",
    "File is required",
    (value, context) =>
      EditDetails && EditDetails.attachment ? true : !!value
  )
  .test("fileSize", "File is too large", (value) => {
    return value ? value.size <= 50000000 : true; // Only validate size if file exists
  })
  .test("fileType", "Invalid file type", (value) => {
    const validTypes = ["image/png", "image/jpeg", "application/pdf", "video/mp4"];
    return value ? validTypes.includes(value.type) : true;
  }),

    }),


    onSubmit: (values) => {
      setLoading(true);
      const formData = new FormData();
      formData.append("property_type", values.type);
      formData.append("status", values.status === "active" ? 1 : values.status === "inactive" ? 2 : "");


      let isExistingAttachment = false; // Flag to track if it's an existing attachment

      // Check if a new image is selected
      if (isImageSelected === 1) {
        formData.append("propertyTypeAttachment", selectedImage); // New file uploaded
        isExistingAttachment = false; // New image means it's not the existing attachment
      } else if (
        selectedImage?.startsWith("http") ||
        selectedImage?.startsWith("https")
      ) {
        // If it's an existing attachment (URL starts with http or https)
        isExistingAttachment = true; // Reuse existing image
      } else if (selectedImage) {
        // If selectedImage is a local file but not a URL, append it as a new file
        formData.append("propertyTypeAttachment", selectedImage);
        isExistingAttachment = false; // Since a file is chosen, it's a new image
      }

      if (EditDetails) {
        formData.append("existing_attachment", isExistingAttachment); // Pass true or false based on the condition

        dispatch(updatePropertyType(EditDetails.id, formData))
          .then((response) => {
            ResponseHandler(response);
            onclose(false);
          })
          .catch((error) => {
            console.error(
              "Update Error:",
              error.response ? error.response.data : error.message
            );
            ResponseHandler(error);
          })
          .finally(() => {
            setLoading(false);
          });
      } else {
        dispatch(createPropertyType(formData))
          .then((response) => {
            ResponseHandler(response);
            onclose(false);
          })
          .catch((error) => {
            console.error(
              "Create Error:",
              error.response ? error.response.data : error.message
            );
            ResponseHandler(error);
          })
          .finally(() => {
            setLoading(false);
          });
      }
    },
  });
  useEffect(() => {
    if (EditDetails) {
      formik.setValues({
        ...formik.values,
        type: EditDetails ? EditDetails.types : "",
        status: EditDetails ? EditDetails.status : "active",
      });
    }
  }, [EditDetails]);

  return (
    <div className="property_Form">
      <form onSubmit={formik.handleSubmit}>
        <p className="property_form_label">Property type</p>
        <TextField
          name="type"
          autoComplete="off"
          size="small"
          placeholder="Enter property type"
          className="property_type_form_input"
          fullWidth
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.type}
          error={formik.touched.type && Boolean(formik.errors.type)}
          helperText={formik.touched.type && formik.errors.type}
          FormHelperTextProps={{
            style: { marginLeft: 0 },
          }}
        />
        <p className="property_form_label">Status</p>
        <Autocomplete
          className="property_type_form_input"
          name="status"
          size="small"
          fullWidth
          onChange={(_, value) => formik.setFieldValue("status", value)}
          onBlur={formik.handleBlur}
          value={formik.values.status || ""} // Ensure value is "" when empty
          options={['active', 'inactive']}
          disableClearable
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder=" Select Status"
              InputLabelProps={{ shrink: false }}
              error={formik.touched.status && Boolean(formik.errors.status)}
              helperText={formik.touched.status && formik.errors.status}
              fullWidth
            />
          )}
        />



        <p className="property_form_label">Upload Files </p>
        <div className="fileuploader_container" >
          {selectedImage && (
            <div>
              {/* <img
            alt="not found"
            width={"250px"}
            src={selectedImage.toString().startsWith("http://") ||
            selectedImage.toString().startsWith("https://")
              ? selectedImage
              : URL.createObjectURL(selectedImage)}
          />
          <br />
          <button onClick={() => setSelectedImage(null)}>Remove</button> */}
              <div className="img-div-attachment">
                <img
                  alt="Selected"
                  src={
                    typeof selectedImage === "object" &&
                      selectedImage instanceof Blob
                      ? URL.createObjectURL(selectedImage) // Only use createObjectURL for File/Blob objects
                      : selectedImage // For URLs (http or https), use the URL directly
                  }
                  className="image_attachment"
                />
                <div
                  className="attachment_close_icon_Propertytype"
                  onClick={() => {
                    setSelectedImage(null);
                    setIsImageSelected(0); // Reset the state to 0 when the image is removed
                  }}
                >
                  <CancelIcon className="attachemnt_cancelIcon" />
                </div>
              </div>
            </div>
          )}

          <>
            <div
              className="file-upload-containers"
              style={{ textAlign: "center", marginTop: "20px", display: "flex", justifyContent: "center" }}
            >
              {!selectedImage && (
                <div>
                  <input
                    type="file"
                    accept=".png, .jpg, .jpeg"
                    name="myImage"
                    onChange={(event) => {
                      const file = event.target.files[0];
                      setSelectedImage(file);
                      formik.setFieldValue("file", file);
                      setIsImageSelected(1);
                    }}
                    style={{ display: "none" }}
                    id="file-input"
                  />
                  <label
                    htmlFor="file-input"
                    style={{ cursor: "pointer", display: "inline-block" }}
                  >
                    <div
                      style={{
                        width: "50px",
                        height: "50px",
                        borderRadius: "50%",

                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",

                        margin: "0 auto",
                        position: "relative",
                      }}
                      onChange={(event) => {
                        const file = event.target.files[0];
                        setSelectedImage(file);
                        formik.setFieldValue("file", file);
                        setIsImageSelected(1);
                      }}
                    >
                      <CloudUploadOutlinedIcon style={{ fontSize: "40px", color: "#1da1f2" }} />
                    </div>
                    < p style={{ color: "#9093a1", fontSize: "14px" }}> JPG, PNG and PDG  files upto 50 Mb</p>
                  </label>
                </div>
              )}
            </div>
            {!selectedImage && (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "12px",
                }}
              >
                <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                  <Button
                    variant="outlined"

                    component="span"
                    style={{ color: "#9093a1", fontFamily: 'Inter', fontSize: "12px", padding: "3px 10px" }} // Custom blue color
                  >
                    Browse
                  </Button>
                </label>
              </div>

            )}
            {/* {!EditDetails ? ( */}
            <FormHelperText
              style={{
                marginLeft: 0,
                color: "#e74c3c",
                fontSize: 13,
                display: "flex",
                justifyContent: "center",
              }}
            >
              {formik.touched.file && formik.errors.file}
            </FormHelperText>
            {/* ) : null} */}
          </>
        </div>
        <div className="property_type_form_action">
          <FormButton
            id="propertyTypeForm"
            type="submit"
            variant="contained"
            loading={loading}
            text={EditDetails ? "Update" : "Add"}
          />
          <FormButton
            id="propertyTypeCancel"
            variant="outlined"
            loading={false}
            text="Cancel"
            onClick={() => onclose(false)}
          />
        </div>
      </form>
    </div>
  );
}

export default PropertyTypeForm;
