import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { getAllApplicationOwnerNames } from "../../../../../../api/apiServices/applicationOwnerApi";
import { getAllStatus } from "../../../../../../api/apiServices/statusApi";
import ResponseHandler from "../../../../../../services/ResponseHandler";

import { getAllVehicleType } from "../../../../../../api/apiServices/vehicleTypeApi";
import dayjs from "dayjs";
import * as Yup from "yup";
import Button from "@mui/material/Button";
import CancelIcon from "@mui/icons-material/Cancel";
import CloudUploadOutlinedIcon from "@mui/icons-material/CloudUploadOutlined";
import CustomAutoComplete from "../../../../../../components/customAutoComplete/CustomAutoComplete";
import CustomTextField from "../../../../../../components/customTextField/CustomTextField";
import CustomDatePicker from "../../../../../../components/customDatePicker/CustomDatePicker";
import FormButton from "../../../../../../components/formButton/FormButton";
import { RiFileEditFill } from "react-icons/ri";
import { getPropertyType } from "../../../../../../api/apiServices/propertyTypeApi";
import "./BankDetailsForm.css";
import {
  
  updatePropertyAuctionAttachment,
  getPropertyAuctionAttachment,
} from "../../../../../../api/apiServices/propertyApi";
import { LocalStorageServices } from "../../../../../../services/localStorageServices";
import { ErrorToast } from "../../../../../../toasts/Toasts";

function Property_BankDetailsForm() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { search } = useLocation();
  const queryParams = new URLSearchParams(search);
  const iSViewAndEdit = queryParams.get("view_edit");
  const [ApplicationOwnerNames, SetApplicationOwnerNames] = useState([]);
  const [Status, SetStatus] = useState([]);
  const [Loading, SetLoading] = useState(false);
  const [Isview, SetIsview] = useState(true);
  const [attachments, setAttachments] = useState([]);
  const [selectedImages, setSelectedImages] = useState([]);
  const [isImageSelected, setIsImageSelected] = useState(0);
  const propertyTypeData = useSelector(
    (state) => state.propertyTypeStore.PropertyTypes
  );
  const { property_id } = useParams();
 // Store multiple files

  useEffect(() => {
    if (iSViewAndEdit) {
      SetIsview(true);
    }
    if (property_id) {
      dispatch(getPropertyAuctionAttachment(property_id))
        .then((response) => {
          const data = response.data.data;
          setAttachments(data ? data : []);
        })
        .catch((error) => {
          ResponseHandler(error);
        });
    }
  }, [property_id]);

 
  const handleFileChange = (event) => {
    const files = Array.from(event.target.files); // Convert FileList to an array
    const allowedTypes = ["image/jpeg", "image/png", "application/pdf"]; // List of allowed file types
  
    // Filter out files that are not in the allowed types list
    const validFiles = files.filter((file) =>
      allowedTypes.includes(file.type)
    );
  
    // Check if any video files were included
    const videoFiles = files.filter((file) => file.type.startsWith("video/"));
    if (videoFiles.length > 0) {
      ErrorToast("Video files are not allowed.");
    }
  
    // Add valid files to the state
    setSelectedImages((prevImages) => [...prevImages, ...validFiles]);
  };
  const handleRemoveImage = (indexToRemove) => {
    setSelectedImages((prevImages) =>
      prevImages.filter((_, index) => index !== indexToRemove)
    );
  };

  const onSubmit = () => {
    const data = new FormData();

    // Append each selected image to form data (newly added images only)
    selectedImages.forEach((file) => {
      data.append("property_auction_file_attachment", file);
    });

    // Example of passing deleted attachments (if needed)
    const deletedAttachments = attachments
      .filter((attachment) => attachment.isDeleted)
      .map((attachment) => attachment.anfa_id)
      .join(",");
      if (deletedAttachments.length > 0) {
    data.append("deleted_attachments", deletedAttachments);}

    SetLoading(true);
    dispatch(updatePropertyAuctionAttachment(property_id, data))
      .then((response) => {
        ResponseHandler(response);
        if (iSViewAndEdit) {
          navigate(
            `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
          );
        } else {
          navigate(
            `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
          );
        }
      })
      .catch((error) => {
        ResponseHandler(error);
      })
      .finally(() => {
        SetLoading(false);
      });
  };

  // Function to handle form submission
  // const handleSubmit =  (event) => {
  //   console.log("handleSubmit");
    
  //   event.preventDefault(); // Prevent default form submission behavior
  //   const formData = new FormData();

  //   selectedImages.forEach((file) => {
  //     formData.append("Property_auction_file_attachments", file);
  //   });


  //   SetLoading(true);
  //   try {
  //     const response =  dispatch(getPropertyAuctionAttachment(formData));
  //     ResponseHandler(response);
  //     console.log(response);
      
  //     navigate(
  //       `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
  //     );
  //   } catch (error) {
  //     ResponseHandler(error);
  //   } finally {
  //     SetLoading(false);
  //   }
  // };

  const handleRemoveAttachment = (attachmentToRemove) => {
    setAttachments((prev) =>
      prev.map((att) =>
        att.anfa_id === attachmentToRemove.anfa_id	
          ? { ...att, isDeleted: true } // Mark as deleted instead of removing it
          : att
      )
    );
  };

  const Privilages = useSelector((state) => state.userStore.Privilages);
  return (
    <div>
    <div className="Property_BankDetails_formContainer" style={{ marginTop: "30px" }}>
      <p className="Property_BankDetails_form_legend">Add Property Auction Attachments</p>
      {Privilages &&
        Privilages?.Property &&
        Privilages?.Property.some((item) => item === "Update")
          ? Isview && (
              <div
                className="Property_detailsfrom_edit_details"
                onClick={() => SetIsview(false)}
              >
                <RiFileEditFill />
                <p>Edit</p>
              </div>
            )
          : null}
      <form id="Property_BankDetailsform" onSubmit={onSubmit}>
        <div className="Property_BankDetailsform_fields">
          <div className="fileuploader_container_Auction_Attachment">
            <div
              className="file-upload-containers_Attachment"
              style={{
                textAlign: "center",
                marginTop: "50px",
                marginBottom: "55px",
              }}
            >
              <p className="Attachment_title">Upload Property Attachment Files</p>
              <div>
                <input
                    disabled={Isview}
                  type="file"
                  accept=".png, .jpg, .jpeg, .pdf, .mp4"
                  name="myImage"
                  onChange={handleFileChange}
                  style={{ display: "none" }}
                  id="file-input"
                  multiple
                />
                <label
                  htmlFor="file-input"
                  style={{ cursor: "pointer", display: "inline-block" }}
                >
                  <div
                    style={{
                      width: "50px",
                      height: "50px",
                      borderRadius: "50%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                      margin: "0 auto",
                      position: "relative",
                    }}
                  >
                    <CloudUploadOutlinedIcon
                      style={{ fontSize: "40px", color: "#1da1f2" }}
                    />
                  </div>
                </label>
                <p style={{ color: "#9093a1", fontSize: "14px" }}>
                  JPG, PNG and PDF files up to 50 Mb
                </p>
              </div>

              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  margin: "12px",
                }}
              >
                <label htmlFor="file-input" style={{ cursor: "pointer" }}>
                  <Button
                      disabled={Isview}
                    variant="outlined"
                    component="span"
                    style={{
                      color: "#9093a1",
                      fontFamily: "Inter",
                      fontSize: "12px",
                      padding: "3px 10px",
                      marginLeft: "-20px",
                    }}
                  >
                    Browse
                  </Button>
                </label>
              </div>
            </div>

            {/* Display existing attachments */}
            <div className="Attachment_lists">
              {attachments .filter((attachment) => !attachment.isDeleted) 
              .map((attachment, index) => (
                <div className="img-div-attachment" key={attachment.anfa_id}>
                  <img
                    alt="Existing"
                    src={attachment.anfa_file_path	}
                    className="image_attachment"
                  />
                  <div
                    className="attachment_close_icon_Propertytype"
                    onClick={() => handleRemoveAttachment(attachment)}
                  >
                    <CancelIcon className="attachemnt_cancelIcon"  style={{ display: Isview ? "none" : "block" }}  />
                  </div>
                </div>
              ))}

              {/* Display newly selected images */}
              {selectedImages.map((file, index) => (
                <div className="img-div-attachment" key={index}>
                  <img
                    alt="Selected"
                    src={URL.createObjectURL(file)}
                    className="image_attachment"
                  />
                  <div
                    className="attachment_close_icon_Propertytype"
                    onClick={() => handleRemoveImage(index)}
                  >
                    <CancelIcon className="attachemnt_cancelIcon"   />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </form>
      {!Isview && (
        <div className="Attachmentform_action">
          <FormButton
            id="AttachmentCreateCancel"
            variant="outlined"
            loading={false}
            text="Cancel"
            onClick={() => {
              navigate(
                `/admin_portal/${LocalStorageServices.getUserRole()}/home/property/properties`
              );
            }}
          />
          <FormButton
            id="AttachmentCreateSubmit"
            type="submit"
            form="Attachmentform"
            variant="contained"
            onClick={onSubmit}
            loading={Loading}
            text="Save & Continue"
          />
        </div>
       )}
    </div>
  </div>
  );
}

export default Property_BankDetailsForm;
