import React, { useEffect, useState } from 'react'
import { useFormik } from "formik";
import * as Yup from "yup";
import FormButton from '../formButton/FormButton';
import CustomAutoComplete from '../customAutoComplete/CustomAutoComplete';
import { saleDropdown } from './dropdownData';
import { getAllHashTags } from '../../api/apiServices/hashTagApi';
import { createVehicleSale, getAllVehicles, getVehicleSaleDetailsById, updateVehicleSale } from '../../api/apiServices/vehicleApi';
import ResponseHandler from '../../services/ResponseHandler';
import { createPropertySale, getAllproperties, getPropertySaleDetailsById, updatePropertySale ,getAllSaleTypes} from '../../api/apiServices/propertyApi';
import CustomDatePicker from '../customDatePicker/CustomDatePicker';
import dayjs from "dayjs";
import CustomTextField from '../customTextField/CustomTextField';
import { useDispatch } from 'react-redux';
import { saleitemList } from '../../constant/SaleStatusList';
function SaleStatus({ id, type, onClose ,eligiblesale}) {
  const dispatch = useDispatch()
  const [Hashtag, SetHashTag] = useState([])
  const [SaleType, SetSaleType] = useState([])
  const [data, Setdata] = useState(null)
  useEffect(() => {
    if (id) {
    //  if (type === "property") {
        getPropertySaleDetailsById(id).then(response => {
          Setdata(response)
          console.log(data,"pp");
          
          formik.setValues({
            ...formik.values,
            ["sale_type_id"]: response.sd_sale_type,
            ["hashtag_id"]: response.sd_priority,
            ["property_id"]: id,
            ["sale_title"]: response.sd_sale_title	
          });
        })
          .catch(error => {
            console.log(error);
          });
      // }

    }
    console.log(eligiblesale,"eligiblesale");
    
    getAllHashTags()
      .then(response => {
        reStructureHashTag(response)
      })
      .catch(error => {
        console.log(error);
      });

      getAllSaleTypes()
      .then(response => {
        reStructureSaleType(response)
      })
      .catch(error => {
        console.log(error);
      });
  
  }, [id])
  function reStructureHashTag(response) {
    const tags = response.map((item) => ({ label: item.lp_tagname, value: item.lp_id }));
    SetHashTag(tags);
  }
  


function reStructureSaleType(response) {
const Saletypes = response.map((item) => ({ label: item.stl_type_name, value: item.stl_id }));
SetSaleType(Saletypes);
}
  const inputStyles = {
    "& .MuiOutlinedInput-root": {
      padding: "0px",
      fontSize: "15px !important",
      fontFamily: "Inter",
      width: 200,
      "& .MuiAutocomplete-input": {
        padding: "7.5px 4px 7.5px 9px !important",
        fontFamily: "Inter",
      },
      "& fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
      },
      "&:hover fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
      },
      "&.Mui-focused fieldset": {
        borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
        outline: "none !important", // Remove outline when focused
      },
    },
  };
  const formik = useFormik({
    initialValues: {
      sale_type_id: '1',
      sale_title: '',
      hashtag_id: null,
    
    },
    validationSchema: Yup.object().shape({
      sale_type_id: Yup.string().required("Sale type is required"),
      sale_title: Yup.string().when("sale_type_id", {
        is: '1',
        then: () => Yup.string().required("Sale title is required"),

      }),
      hashtag_id: Yup.string().nullable().when("sale_type_id", {
        is: '1',
        then: () => Yup.string().nullable().required("Hash tag is required")
      }),
    }),
    onSubmit: (values) => {
      console.log(values,"values");
      
      // createPropertySale(id, values).then((response) => {
      //   onClose();

      //   dispatch(getAllproperties());
      //   ResponseHandler(response);
      // }).catch((error) => {
      //   ResponseHandler(error);
      // });
      if (!data) {
        
          createPropertySale(id, values).then((response) => {
            onClose();

            dispatch(getAllproperties());
            ResponseHandler(response);
          }).catch((error) => {
            ResponseHandler(error);
          });
        // }
      } else {
      
          updatePropertySale(data.sd_property_id	, values).then((response) => {
            onClose();
            dispatch(getAllproperties());
            ResponseHandler(response);
          }).catch((error) => {
            ResponseHandler(error);
          });
      
      }
    }
    
  });
  return (
    <div className="update_property_task_assigne_Form">
      <form onSubmit={formik.handleSubmit}>
        <p className="update_property_task_assigne_Form_label">Sale type</p>
        <CustomAutoComplete
          name="sale_type_id"
          placeholder="Select "
          options={SaleType}
          getOptionLabel={(option) => option.label}
          sx={{ ...inputStyles }}
          onChange={(event, newValue) => {
            // formik.setFieldValue("sale_type_id", newValue ? newValue.value : "");
            formik.setValues({
              ...formik.values,
              ["sale_type_id"]: newValue ? newValue.value : "",
              ["hashtag_id"]:null,
              ["property_id"]: id,
              ["sale_title"]:""
            });
            // formik.setFieldValue("hashtag_id", null);
          }}
          onBlur={formik.handleBlur}
          value={
            SaleType.find(
              (SaleType) => SaleType.value === formik.values.sale_type_id
            ) || null
          }
          isOptionEqualToValue={(option, value) =>
            option.value.toString() === value.value.toString()
          }
          error={formik.touched.sale_type_id && Boolean(formik.errors.sale_type_id)}
          helperText={formik.touched.sale_type_id && formik.errors.sale_type_id}
        />
        {formik.values.sale_type_id === 1 ? <>
          <p className="update_property_task_assigne_Form_label">Sale Title</p>
          <CustomTextField
            name="sale_title"
            placeholder="Enter sale title"
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.sale_title}
            error={
              formik.touched.sale_title && Boolean(formik.errors.sale_title)
            }
            helperText={
              formik.touched.sale_title && formik.errors.sale_title
            }
          />
          <p className="update_property_task_assigne_Form_label">Hash tag</p>
          <CustomAutoComplete
            name="hashtag_id"
            placeholder="Select tag"
            options={Hashtag}
            getOptionLabel={(option) => option.label}
            sx={{ ...inputStyles }}
            onChange={(event, newValue) => {
              formik.setFieldValue("hashtag_id", newValue ? newValue.value : "");
            }}
            onBlur={formik.handleBlur}
            value={
              Hashtag.find(
                (Hashtag) => Hashtag.value === formik.values.hashtag_id
              ) || null
            }
            isOptionEqualToValue={(option, value) =>
              option.value.toString() === value.value.toString()
            }
            error={formik.touched.hashtag_id && Boolean(formik.errors.hashtag_id)}
            helperText={formik.touched.hashtag_id && formik.errors.hashtag_id}
          />

        </> : <></>}

        <div className="changePassword_form_action">
          <FormButton
            id="changePasswordCreateForm_update"
            type="submit"
            variant="contained"
            loading={false}
            text={eligiblesale === null ? "Add" : "Update"}
          />
          <FormButton
            id="changePasswordCreateForm_Cancel"
            variant="outlined"
            loading={false}
            text="Cancel"
            onClick={() => onClose()}
          />
        </div>
      </form>
    </div>
  )
}

export default SaleStatus