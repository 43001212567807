import React, { useState } from 'react'
import { Button, TextField } from "@mui/material";
import { DateRangePicker } from "react-date-range";
import './PropertyEnquireyFilterStyle.css'

function PropertyEnquireyFilter({values,onChange,onchangeDateRange,onReset,onSubmit}) {
    const formatDate = (date) => {
        const year = date.getFullYear();
        const month = String(date.getMonth() + 1).padStart(2, '0');
        const day = String(date.getDate()).padStart(2, '0');
        return `${year}-${month}-${day}`;
      };
    
      const handleSelect = (ranges) => {
        const issue_start_date = formatDate(ranges.selection.startDate);
        const issue_end_date = formatDate(ranges.selection.endDate);
        onchangeDateRange([issue_start_date,issue_end_date])
      };
    
      const handleFieldClick = () => {
        togglePickerView("custom_date_picker_container");
      };
    
      const togglePickerView = (id) => {
        const element = document.getElementById(id);
        if (element) {
          if (element.style.display === "none" || element.style.display === "") {
            element.style.display = "block";
          } else {
            element.style.display = "";
          }
        }
      };
      window.addEventListener("click", function (e) {
        try {
          if (
            !document
              .getElementById("custom_date_picker_field")
              ?.contains(e.target) &&
            !document
              .getElementById("custom_date_picker_container")
              ?.contains(e.target)
          ) {
            const element = document.getElementById("custom_date_picker_container");
            if (element) {
              if ((element.style.display = "block")) {
                element.style.display = "";
              }
            }
          }
        } catch (error) {
          console.log(error);
        }
      });
      const parseDate = (dateString) => {
        return dateString ? new Date(dateString) : new Date();
      };
      const ModifiedTextField = {
        '& .MuiOutlinedInput-root': {
          fontFamily: 'Inter, Arial, sans-serif !important', // Fallbacks for safety
          fontSize: '15px',
          '& fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
          '&:hover fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
        },
        '& .MuiInputBase-input': {
          fontFamily: 'Inter, Arial, sans-serif !important',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#272727 !important',
        },
      };

    return (
        <div>
        <div className="PropertyEnquire_input_action_container">
            <TextField
                name="property_id"
                value={values.property_id}
                fullWidth
                className="PropertyEnquire_input"
                autoComplete="off"
                style={{ fontSize: "15px" }}
                size="small"
                placeholder={"Enter property id"}
                onChange={onChange}
                variant="outlined"
                inputProps={{
                    style: {
                        padding: "7.5px 7px", // Add padding to input
                        fontSize: "15px",
                        fontWeight: 400,
                        fontFamily: "Inter",
                        borderRadius: "4px",
                    },
                }}
                sx={ModifiedTextField}
            />
            <TextField
                name="name"
                value={values.name}
                fullWidth
                className="PropertyEnquire_input"
                autoComplete="off"
                style={{ fontSize: "15px" }}
                size="small"
                placeholder={"Enter name"}
                onChange={onChange}
                inputProps={{
                    style: {
                        padding: "7.5px 7px", // Add padding to input
                        fontSize: "15px",
                        fontWeight: 400,
                        fontFamily: "Inter",
                        borderRadius: "4px",
                    },
                }}
                sx={ModifiedTextField}
            />
            <TextField
                name="email"
                value={values.email}
                fullWidth
                className="PropertyEnquire_input"
                autoComplete="off"
                style={{ fontSize: "15px" }}
                size="small"
                placeholder={"Enter email"}
                onChange={onChange}
                inputProps={{
                    style: {
                        padding: "7.5px 7px", // Add padding to input
                        fontSize: "15px",
                        fontWeight: 400,
                        fontFamily: "Inter",
                        borderRadius: "4px",
                    },
                }}
                sx={ModifiedTextField}
            />
            <TextField
                name="contactNumber"
                value={values.contactNumber}
                fullWidth
                className="PropertyEnquire_input"
                autoComplete="off"
                style={{ fontSize: "15px" }}
                size="small"
                placeholder={"Enter contact number"}
                onChange={onChange}
                inputProps={{
                    style: {
                        padding: "7.5px 7px", // Add padding to input
                        fontSize: "15px",
                        fontWeight: 400,
                        fontFamily: "Inter",
                        borderRadius: "4px",
                    },
                }}
                sx={ModifiedTextField}
            />
              <div>
              <div
                id="custom_date_picker_field"
                className="custom_vehicle_date_picker_field"
                onClick={handleFieldClick}
              >
                <p>
                  {values?.start_date ? (
                    values?.start_date
                  ) : (
                    <span
                      className="customPlaceholder"
                      style={{ fontFamily: "Inter", fontSize: "15px" }}
                    >
                      Start date
                    </span>
                  )}
                  {" - "}
                  {values?.end_date ? (
                    values?.end_date
                  ) : (
                    <span
                      className="customPlaceholder"
                      style={{ fontFamily: "Inter", fontSize: "15px" }}
                    >
                      End date
                    </span>
                  )}
                </p>
              </div>
              <div
                id={"custom_date_picker_container"}
                className="custom_date_picker_container"
              >
                <DateRangePicker
                 ranges={[
                    {
                      startDate: parseDate(values.start_date),
                      endDate: parseDate(values.end_date),
                      key: "selection",
                    },
                  ]}
                  onChange={handleSelect}
                  className="custom-date-picker"
                />
              </div>
            </div>
            <div className="PropertyEnquire_action">
                <Button
                    className="users_Search_button"
                    onClick={onSubmit}
                    variant="contained"
                    size="small"
                    style={{
                        textTransform: "none",
                        textTransform: "none",
                        fontFamily: "Inter",
                        fontSize: "15px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        lineHeight: "normal",
                        padding: "8px 15px",
                        backgroundColor: "#0f62fe",
                        color: "white",
                        border: "none",
                        borderRadius: "5px",
                        cursor: "pointer"
                    }}
                >
                    {"Search"}
                </Button>
                <Button
                    onClick={onReset}
                    className="users_Reset_button"
                    variant="outlined"
                    size="small"
                    style={{
                        textTransform: "none",
                        cursor: 'pointer',
                        fontFamily: 'inter',
                        border: '1px solid #005eb8',
                        backgroundColor: 'white',
                        borderRadius: '5px',
                        padding: '7px 25px',
                        color: '#0f62fe',
                        fontSize: '14px'
                    }}

                >
                    {"Reset"}
                </Button>
            </div>
        </div>
    </div>
    )
}

export default PropertyEnquireyFilter