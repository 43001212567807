import React, { useState, useEffect } from "react";
import { Button, IconButton, TextField } from "@mui/material";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { useDispatch, useSelector } from "react-redux";
import {
  filterByRoleName,
  resetFilterData,
  setFilterRoleName,
} from "../../../../../../../store/rolesStore";
import "./RoleFilter.css";

const translateText = async (text, targetLang = 'es') => {
  const currentLang = localStorage.getItem('currentLanguage') || 'en';
  if (currentLang === targetLang) {
    try {
      const response = await fetch(
        `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=en|${targetLang}`
      );
      if (!response.ok) throw new Error('Network response was not ok');
      const data = await response.json();
      return data.responseData.translatedText;
    } catch (error) {
      console.error('Error translating text:', error);
      return text; // Fallback to original text if translation fails
    }
  }
  return text;
};

function RoleFilter() {
  const dispatch = useDispatch();
  const RoleName = useSelector((state) => state.roleStore.FilterByRoleName);
  const [originalRoleName, setOriginalRoleName] = useState(RoleName);
  const [translatedRoleName, setTranslatedRoleName] = useState('');

  useEffect(() => {
    const updateTranslation = async () => {
      console.log("Translating text:", originalRoleName); // Log original role name
      try {
        const translatedRole = await translateText(originalRoleName);
        console.log("Translated text:", translatedRole); // Log translated text
        setTranslatedRoleName(translatedRole);
      } catch (error) {
        console.error('Error in updateTranslation:', error);
      }
    };
    if (originalRoleName) { // Only translate if there's a value
      updateTranslation();
    } else {
      setTranslatedRoleName(''); // Clear translation if input is empty
    }
  }, [originalRoleName]);

  console.log("Translated Role Name:", translatedRoleName);
  console.log("Original Role Name:", originalRoleName);

  const FilterData = async () => {
    dispatch(setFilterRoleName(originalRoleName)); // Pass the original value for filtering
    dispatch(filterByRoleName());
    
    if (window.innerWidth <= 650) {
      UpdateFieldVisibility("role_filter_field_container");
    }
  };

  const ResetFilter = () => {
    setOriginalRoleName('');
    setTranslatedRoleName('');
    dispatch(setFilterRoleName(""));
    dispatch(resetFilterData());
  };

  function UpdateFieldVisibility(id) {
    const element = document.getElementById(id);
    if (element) {
      element.style.display = (element.style.display === "none" || element.style.display === "") ? "block" : "";
    }
  }

  useEffect(() => {
    const handleClickOutside = (event) => {
      const icon = document.querySelector(".role_filter_Icon");
      const container = document.querySelector(".role_filter_field_container");
      const sort = document.querySelector(".role_filter_input_container");
      const searchButton = document.querySelector(".role_filter_action_button");
      const textfield = document.querySelector(".roles_Reset_button");

      if (
        !container?.contains(event.target) &&
        !icon?.contains(event.target) &&
        !sort?.contains(event.target) &&
        !searchButton?.contains(event.target) &&
        !textfield?.contains(event.target)
      ) {
        setFilterOpen(false);
      }
    };
    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  const [filterOpen, setFilterOpen] = useState(false);
  const ModifiedTextField = {
    '& .MuiOutlinedInput-root': {
      fontFamily: 'Inter, Arial, sans-serif !important', // Fallbacks for safety
      fontSize: '15px',
      '& fieldset': {
        borderColor: 'rgba(119, 182, 243, 1) !important',
      },
      '&:hover fieldset': {
        borderColor: 'rgba(119, 182, 243, 1) !important',
      },
      '&.Mui-focused fieldset': {
        borderColor: 'rgba(119, 182, 243, 1) !important',
      },
    },
    '& .MuiInputBase-input': {
      fontFamily: 'Inter, Arial, sans-serif !important',
    },
    '& .MuiInputBase-input.Mui-disabled': {
      WebkitTextFillColor: '#272727 !important',
    },
  };
  
  return (
    <div>
      <div className="role_filter_Icon">
        <IconButton
          color="primary"
          onClick={() => {
            UpdateFieldVisibility("role_filter_field_container");
            setFilterOpen(!filterOpen);
          }}
        >
          <FilterAltOutlinedIcon />
        </IconButton>
      </div>
      <div
        id="role_filter_field_container"
        className="role_filter_field_container"
        style={{ display: filterOpen ? "block" : "" }}
      >
        <div className="role_filter_input_container">
          <TextField
            autoComplete="off"
            className="role_input"
            value={translatedRoleName}
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter role"}
            onChange={(e) => {
              const inputValue = e.target.value;
              setOriginalRoleName(inputValue); // Update original role name
            }}
            inputProps={{
              style: {
                padding: "7.5px 7px",
                fontSize: "15px",
                fontWeight: 400,
                fontFamily: "Inter",
                borderRadius: "4px",
              },
            }}
            sx={ModifiedTextField}
          />
          <div className="role_filter_action_button">
            <Button
              className="roles_Search_button"
              onClick={FilterData}
              variant="contained"
              size="small"
              style={{
                textTransform: "none",
                fontFamily: "Inter",
                fontSize: "14px",
                fontStyle: "normal",
                fontWeight: 600,
                lineHeight: "normal",
                padding: "8px 15px",
                backgroundColor: "#0f62fe",
                color: "white",
                border: "none",
                borderRadius: "5px",
                cursor: "pointer",
              }}
            >
              Search
            </Button>
            <Button
              onClick={ResetFilter}
              className="roles_Reset_button"
              variant="outlined"
              size="small"
              style={{ textTransform: "none", fontFamily: "Inter" }}
            >
              Reset
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RoleFilter;
