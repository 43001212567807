import React, { useEffect, useState } from "react";
import { BsShare } from "react-icons/bs";
import { IoLocationSharp } from "react-icons/io5";
import { HiArrowsPointingOut } from "react-icons/hi2";
import { Card, Grid, Typography, Button, Stack } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import "./AgentPropertyView.css";
import { useDispatch } from "react-redux";
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import { ApiConfig } from "../../../../../../api/apiConfig";
import { FaMapMarkerAlt } from "react-icons/fa";
import { PiBathtubBold } from "react-icons/pi";
import PropertyMap from "./PropertyMap";
import Propertyslide from "./PropertySlide";
import { IoMdHeartEmpty } from "react-icons/io";
import { IoHeart } from "react-icons/io5";
import { ErrorToast } from "../../../../../../toasts/Toasts";
import { useSelector } from "react-redux";
import CustomSpin from "../../../../../../components/customSpin/CustomSpin";
import noimage from "../../../../../../assets/no image.png"
import Skeleton from '@mui/material/Skeleton';
import { Helpers } from "../../../../../../services/Helpers";
import ResponseHandler from "../../../../../../services/ResponseHandler";
import DownloadIcon from '@mui/icons-material/Download';
import TouchAppOutlinedIcon from '@mui/icons-material/TouchAppOutlined';
import CurrencyRupeeOutlinedIcon from '@mui/icons-material/CurrencyRupeeOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import GroupsIcon from '@mui/icons-material/Groups';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import TimerOutlinedIcon from '@mui/icons-material/TimerOutlined';
import FeedOutlinedIcon from '@mui/icons-material/FeedOutlined';
import HomeWorkOutlinedIcon from '@mui/icons-material/HomeWorkOutlined';
import { GetAuctionDocument } from "../../../../../../api/apiServices/agentApi";

function AgentPropertyView() {
  const navigate = useNavigate();
  // Added store for getting token (to check is logined or not)
  const token = useSelector((state) => state.userStore.token);
  const { id } = useParams();
  const dispatch = useDispatch()
  const [SimilarPrperty, SetSimilarPrperty] = useState([]);
  const [SuggestedPrperty, SetSuggestedPrperty] = useState([]);
  const [wishlistIds, setWishlistIds] = useState([]);
  const [Propertydetails, SetPropertydetails] = useState([]);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  const [attachment, SetAttachment] = useState([]);
  const [propertyid, setpropertyid] = useState(false);
  const [openmodal, setOpenModal] = useState(false);
  const [PropertyRecom, setPropertyRecom] = useState([]);
  const [hasRecommendations, setHasRecommendations] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = (state) => {
    setOpen(state);
    // getCustomerDetails();
  };
  console.log(id, "property_id");
  const steps = [
    {
      stepNumber: '01',
      title: 'Choose a Property',
      description: 'Explore our listings & find a property that meets your requirements.',
      icon: <TouchAppOutlinedIcon />, // Replace with your icon library
    },
    {
      stepNumber: '02',
      title: 'Pay the EMD',
      description: 'Pay 10% earnest money deposit as an assurance of interest in the property.',
      icon: <CurrencyRupeeOutlinedIcon />, // Replace with your icon library
    },
    {
      stepNumber: '03',
      title: 'Schedule the Application',
      description: 'Submit the Common Application Form (CAF) and prepare for auction.',
      icon: <AssignmentOutlinedIcon />, // Replace with your icon library
    },
    {
      stepNumber: '04',
      title: 'Participate in Auction',
      description: 'Make an offer for the property,to make it yours',
      icon: <GroupsIcon />,
    },
    {
      stepNumber: '05',
      title: 'Auction Process',
      description: 'If you win the auction, pay 15%. If you lose the auction, get the EMD refund.',
      icon: <EmojiEventsIcon />,
    },
    {
      stepNumber: '06',
      title: 'Pay 75% in 15 Days',
      description: 'Pay the remaining 75% within 15 Days to start the registration process.',
      icon: <TimerOutlinedIcon />,
    },
    {
      stepNumber: '07',
      title: 'Obtain Sale Certificate',
      description: 'The seller institution issues the sale certificate after payment completion.',

      icon: <FeedOutlinedIcon />,
    },
    {
      stepNumber: '08',
      title: 'Register the Property',
      description: 'Authorized officer registers the property in the Sub-Registrar Office.',
      icon: <HomeWorkOutlinedIcon />,
    },
  ];
  const titleColors = ['#ff9933', '#33ccff', '#009933', '#ff6666', '#0066ff', '#66ff66', '#9900ff', '#ff0066'];

  // Calculate the index for the title color based on the step index

  const geSimilarPrperty = async () => {
    try {
      if (id) {
        const result = await ApiConfig.get(
          `/customer/${id}/get_similar_property`
        );
        const { data } = result.data;
        SetSimilarPrperty(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  const geSuggestedPrperty = async () => {
    try {
      if (id) {
        const result = await ApiConfig.get(
          `/customer/${id}/get_similar_property_based_current`
        );
        const { data } = result.data;
        SetSuggestedPrperty(data);
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  const [latitudeLongitude, SetlatitudeLongitude] = useState([0, 0]);

  const [wishlist, SetWishlist] = useState([]);
  const getWishlistlist = async () => {
    const accessToken = localStorage.getItem("up_token");

    if (accessToken) {
      try {
        const result = await ApiConfig.get("/customer/get_property_wishlist");
        const { status } = result;
        const { data } = result.data;
        if (status === 201) {
          SetWishlist(data);
          setWishlistIds(data.map((item) => item.w_property_or_vehicle_id));
        } else {
          ResponseHandler(result);
        }
      } catch (error) {
        ResponseHandler(error);
      }
    }
  };
  const [heartColors, setHeartColors] = useState({}); // State to store heart colors for each card

  // Function to toggle heart color and handle wishlist

  const toggleColor = (ps_property_id) => {
    const newHeartColors = { ...heartColors };
    if (newHeartColors[ps_property_id]) {
      delete newHeartColors[ps_property_id];
    } else {
      newHeartColors[ps_property_id] = true;
    }
    setHeartColors(newHeartColors);
    getWhishlist(ps_property_id);
  };

  const getWhishlist = async (id) => {
    const accessToken = localStorage.getItem("up_token");

    if (accessToken) {
      try {
        const result = await ApiConfig.post(
          `/customer/${id}/property_wishlist`
        );
        const { status } = result;

        if (status == 200) {
          getWishlistlist();
          ResponseHandler(result);
        } else {
          ResponseHandler(result);
        }
      } catch (error) {
        ResponseHandler(error);
      }
    } else {
      const currentPath = window.location.pathname;
      sessionStorage.setItem('redirectPath', currentPath);
      navigate('/login');
    }
  };
  const getPropertydetails = async () => {
    setLoading(true);
    try {
      if (id) {
        const result = await ApiConfig.get(
          `/customer_portal/property/${id}/get_property_sale_details_by_id`
        );
        const { status } = result;
        const { data } = result.data;
        console.log(data);

        if (status == 200) {
          SetPropertydetails(data);
          console.log(Propertydetails);
          setpropertyid(data.pd_id);
          // ResponseHandler(result);
        } else {
          ResponseHandler(result);
        }
        console.log(propertyid, "propertyid");


        // Modified this logic for showing location in proper way
        const { pd_lattitude, pd_longitude } = data;
        if (pd_lattitude && pd_longitude) {
          SetlatitudeLongitude([
            parseFloat(pd_longitude),
            parseFloat(pd_lattitude),
          ]);
        }
      }
    } catch (error) {
      ResponseHandler(error);
    } finally {
      setLoading(false);
    }
  };
  useEffect(() => {
    dispatch(GetAuctionDocument(id)).then((response) => {
      SetAttachment(response)
    }).catch((error) => {
      ResponseHandler(error)
    })
  }, [id])
  const [PropertyIntereststatus, setPropertyIntereststatus] = useState([]);
  const getPropertyAppliedStatus = async () => {
    if (token) {
      // Added store for getting token (to check is logined or not)
      try {
        if (id) {
          const result = await ApiConfig.get(
            `/customer/${id}/get_property_applied_status`
          );
          const { status } = result;
          const { data } = result.data;
          if (status === 200) {
            setPropertyIntereststatus(data);
          } else {
            ResponseHandler(result);
          }
        }
      } catch (error) {
        console.log(error.message);
      }
    }
  };
  const getProperty = async () => {
    try {
      if (id) {
        const result = await ApiConfig.get(
          `/customer/${id}/get_property_recommentation`
        );
        const { status } = result;
        const { data } = result.data;
        if (status === 200) {
          setPropertyRecom(data);
          if (data.length > 0) {
            setHasRecommendations(true);
          } else {
            setHasRecommendations(false);
          }
        } else {
          ResponseHandler(result);
        }
      }
    } catch (error) {
      console.log(error.message);
    }
  };
  useEffect(() => {
    const openModalFlag = sessionStorage.getItem("openModal");
    if (openModalFlag === "true" && hasRecommendations) {
      setOpenModal(true);
      sessionStorage.removeItem("openModal"); // Clean up the flag
      sessionStorage.removeItem("property_id");
    }
  }, [hasRecommendations]);

  const handleClosemodal = () => {
    setOpenModal(false);
    // Optionally, reset location state to avoid reopening modal on refresh
    window.history.replaceState({}, document.title);
  };

  const downloadAttachment = (file) => {
    const link = document.createElement('a');
    link.href = file.file_path;
    link.download = file.file_name;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    // getPropertyAppliedStatus();
    getPropertydetails();
    // geSimilarPrperty();
    // getWishlistlist();
    // getProperty();
    // geSuggestedPrperty();
  }, [id, token]);

  return (
    <>
      <CustomSpin loading={loading}>
        <div className="agent_view_header">
          <p className="page_title">  Property ID:{Propertydetails?.pd_id}{" "}</p>
          <div
            className="back_button"
            onClick={() => {
              navigate(-1);
            }}
          >
            <KeyboardBackspaceIcon />
            <p>back</p>
          </div>
        </div>

        <div style={{ padding: "24px 30px" }}>
          <Grid container spacing={2}>
            {/* First Grid with md=8 */}
            <Grid item xs={12} md={9}>
              <Grid container spacing={6} marginTop={-3}>


                <Grid item xs={12} md={12}>
                  <div className=" Property_details_Titile">
                    <div className="titile_Property">
                      {Propertydetails?.pd_name}
                    </div>


                  </div>
                  <Typography
                    sx={{
                      color: "#9093A1",
                      fontWeight: 300,
                      fontSize: 14,
                     
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        marginTop: 8,
                      }}
                    >
                      <IoLocationSharp />
                      {Propertydetails?.pd_location}
                    </div>
                  </Typography>
                  <div className="custom-typography">
                    <div className="price">
                      $
                      {
                        Propertydetails?.pd_sale_price
                      }{" "}
                    </div>

                  </div>
                  <div style={{display:"flex", flexDirection:"column"}}>
                  <div className="text" style={{fontWeight:600}}>
                    Market Price  :   ₹{Propertydetails?.pd_market_value}{" "}
                  </div>
                  <div className="text">
                    Property Restructure ID:{Propertydetails?.pd_restructure_id}{" "}
                  </div>
                  </div>
                  {/* {Array.isArray(vehiclesdetails) && vehiclesdetails.map((vehiclesdetail, index) => ( */}
                  <Grid container spacing={2}>
                    {/* First Card */}
                    <Grid item xs={12} md={6}>
                      <Card
                        sx={{
                          borderRadius: 4,
                          p: 5,
                          boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                          marginTop: 4,
                          overflow: "auto",
                          height: 400,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: 600,
                            fontSize: 17,
                          
                          }}
                        >
                          Property Details
                        </Typography>
                        <Grid item xs={12}>
                          <div className="Property_details">
                          <div className="Gird_row">
                              <div className="Property_data">Address</div>
                              <div className="Property_value">

                                {
                                  Propertydetails?.pd_address
                                }
                              </div>
                              </div>
                            <div className="Gird_row">
                              <div className="Property_data">Built-up Area</div>
                              <div className="Property_value">
                                {" "}
                                {
                                  Propertydetails?.pd_built_up_area
                                }
                              </div></div>
                            <div className="Gird_row">
                              <div className="Property_data">Total Area</div>
                              <div className="Property_value">
                                {" "}
                                {
                                  Propertydetails?.pd_total_area
                                }
                              </div>
                            </div>
                            <div className="Gird_row">
                              <div className="Property_data">Private Treaty</div>
                              <div className="Property_value">
                              {Propertydetails?.pd_private_treaty === 1 ? "Yes" : "No"}
                              </div>
                            </div>
                            <div className="Gird_row">
                              <div className="Property_data">Property Type</div>
                              <div className="Property_value">
                             
                                {
                                  Propertydetails?.ptl_type
                                }
                              </div>
                            </div>
                            <div className="Gird_row">
                              <div className="Property_data"> Seller</div>
                              <div className="Property_value">
                                
                                {
                                  Propertydetails?.aonl_name
                                }
                              </div>
                            </div>
                            
                          </div>
                          {/* Add other property details here */}
                        </Grid>
                      </Card>
                    </Grid>
                    {/* Second Card */}
                    <Grid item xs={12} md={6}>
                      <Card
                        sx={{
                          borderRadius: 4,
                          p: 5,
                          boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                          marginTop: 4,
                          overflow: "auto",
                          height: 400,
                        }}
                      >
                        <Typography
                          sx={{
                            color: "#000000",
                            fontWeight: 600,
                            fontSize: 17,
                          
                          }}
                        >
                          Auction Details
                        </Typography>

                        <Grid item xs={12}>
                          <div className="Property_details">
                             <div className="Gird_row">
                              <div className="Property_data">Possession</div>
                              <div className="Property_value">
                                {Propertydetails?.ppl_name}
                              </div>
                            </div>
                            <div className="Gird_row">
                              <div className="Property_data">EMD</div>
                              <div className="Property_value">
                              ₹ {Propertydetails?.pd_emd}
                              </div>
                            </div>
                            <div className="Gird_row">
                              <div className="Property_data">EMD percentage</div>
                              <div className="Property_value">
                                {Propertydetails?.pd_emd_percentage	} %
                              </div>
                            </div>
                           
                            <div className="Gird_row">
                              <div className="Property_data">Application Start Date</div>
                              <div className="Property_value">
                                {Propertydetails?.pd_application_end_date}
                              </div>
                            </div>
                            <div className="Gird_row">
                              <div className="Property_data">Application End Date</div>
                              <div className="Property_value">
                                {Propertydetails?.pd_application_start_date	}
                              </div>
                            </div>
                            <div className="Gird_row">
                              <div className="Property_data">Auction Date & Time</div>
                              <div className="Property_value">
                                {Propertydetails?.pd_aution_date}
                              </div>
                            </div>
                          </div>

                        </Grid>


                      </Card>
                    </Grid>
                  </Grid>
                  <Grid item xs={12} md={12}>
                    <Card
                      sx={{
                        borderRadius: 4,
                        p: 5,
                        boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                        marginTop: 5,
                        overflow: "auto",
                      }}
                    >
                      <Typography
                        sx={{
                          color: "#000000",
                          fontWeight: 600,
                          fontSize: 17,
                       

                        }}
                      >
                        Important Note
                      </Typography>
                      {
                        Propertydetails?.pd_important_note
                      }
                    </Card>

                  </Grid>
                  {/* ))} */}
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      borderRadius: 4,
                      // margin: "10px 0",
                      p: 5,
                      boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                      height: 400,
                      overflow: "auto",
                    }}
                  >

                    <Grid container spacing={5}>
                      <Grid item xs={12} md={12}>
                        <div className="Property_card_titile">Location</div>
                        <div className="property_about">
                          <PropertyMap
                            locationCoordinates={latitudeLongitude}
                            style={{ borderRadius: 5 }}
                          />
                        </div>
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
                <Grid item xs={12} md={6}>
                  <Card
                    sx={{
                      borderRadius: 4,
                      // margin: "10px 0",
                      p: 5,
                      boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                      height: 400,
                      overflow: "auto",
                    }}
                  ><div className="Property_card_titile">Property Attachments</div>

                    <Propertyslide />
                  </Card>
                </Grid>

                <Grid item xs={12} md={12}>
                  <Card
                    sx={{
                      borderRadius: 4,
                      p: 5,
                      boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                     
                      overflow: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: 17,
                        fontFamily: "Poppins",

                      }}
                    >
                      Additional Information
                    </Typography>
                    {
                      Propertydetails?.pd_additional_informations
                    }
                  </Card>

                </Grid>

                <Grid item xs={12} md={12}>
                  <Card
                    sx={{
                      borderRadius: 4,
                      p: 5,
                      boxShadow: "1px 2px 5px rgba(0, 0, 0, 0.1)",
                      
                      overflow: "auto",
                    }}
                  >
                    <Typography
                      sx={{
                        color: "#000000",
                        fontWeight: 600,
                        fontSize: 17,
                        fontFamily: "Poppins",

                      }}
                    >
                      Near By Information
                    </Typography>
                    {
                      Propertydetails?.pd_near_by_informations	
                    }
                  </Card>

                </Grid>
          
              </Grid>
            </Grid>
            {/* Second Grid with md=4, empty for now */}
            <Grid item xs={12} md={3}>
              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 5,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  height: 140,
                  overflow: "auto",
                }}
              >
                <div className="Property_card_titile">Auction Attachments</div>
                <Stack spacing={2} mt={5} >
                  {attachment && attachment.length > 0 ? (

                    <Button

                      variant="outlined"
                      startIcon={<DownloadIcon />}
                      onClick={() => Helpers.DownloadAssetsAsZip(attachment)}

                      sx={{
                        color: 'primary.main',
                        borderColor: 'primary.main',
                        '&:hover': {
                          borderColor: 'primary.dark',
                        },
                      }}

                    >
                      Download
                    </Button>
                  ) : null}
                </Stack>

              </Card>

              <Card
                sx={{
                  borderRadius: 4,
                  // margin: "10px 0",
                  p: 5,
                  boxShadow: " 1px 2px 5px rgba(0, 0, 0, 0.1)",
                  // height: 650,
                  // overflow: "auto",
                  mt: 3,
                }}
              >
                <div className="Property_card_titile"> Your Buying Process </div>
                {steps.map((step, index) => (
                  <div className="step">
                    <div className="step-number" style={{ color: titleColors[index % titleColors.length] }}>{step.stepNumber}</div>
                    <div className="Titile_description" style={{ color: '#6784A2' }}>
                      <div className="step-title" style={{ color: '#000' }}>{step.title}</div>
                      <div className="Underline_titile" style={{ backgroundColor: titleColors[index % titleColors.length] }}></div>
                      <div className="step-description">{step.description}</div>
                    </div>

                    <div className="step-icon" style={{ color: titleColors[index % titleColors.length] }}>{step.icon}</div>
                  </div>
                ))}
              </Card>
            </Grid>
          </Grid>
          <Grid item xs={12} md={12} p={3}>
            <div className="Property_card_titile">Disclaimer</div>
            <div className="property_disclaimer">
              What is Lorem Ipsum? Lorem Ipsum is simply dummy text of the
              printing and typesetting industry. Lorem Ipsum has been the
              industry's standard dummy text ever since the 1500s, when an
              unknown printer took a galley of type and scrambled it to make a
              type specimen book. It has survived not only five centuries, but
              also the leap into electronic typesetting, remaining essentially
              unchanged. It was popularised in the 1960s with the release of
              Letraset sheets containing Lorem Ipsum passages, and more recently
              with desktop publishing software like Aldus PageMaker including
              versions of Lorem Ipsum. Why do we use it? It is a long
              established fact that a reader will be distracted by the readable
              content of a page when looking at its layout.
            </div>

            {/* <Divider style={{ marginTop: "20px" }} /> */}
          </Grid>

        </div>
      </CustomSpin>
    </>
  );
}

export default AgentPropertyView;
