import React, { useEffect, useRef ,useState} from "react";
import {
  Grid,
  Stack,
} from "@mui/material";
import useMediaQuery from "@mui/material/useMediaQuery";
import "./PropertySlide.css";

import {  useParams } from "react-router-dom";
import { ApiConfig } from "../../../../../../api/apiConfig"; 
import { Helpers } from "../../../../../../services/Helpers";
import noimage from "../../../../../../assets/no image.png"


const Propertyslide = () => {
  const { id } = useParams();
  const cardListRef = useRef(null);
 
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [isVideo, setIsVideo] = useState(false);
  const [Propertydetails, SetPropertydetails] = useState([]);
 
  const getPropertydetails = async () => {
    try {
        if (id) {
          const result = await ApiConfig.get(
            `/customer_portal/property/${id}/get_property_sale_details_by_id`
          );
          const { data } = result.data;
          SetPropertydetails(data.attachments	);
          const initialMedia = data.attachments[0]?.file_path ?? "";
          setSelectedMedia(initialMedia);
          setIsVideo(Helpers.getUrlExtensions(initialMedia));
        }
      } catch (error) {
        console.log(error.message);
      }
  };
// Modify this logic because the image will not change when click on similar item
useEffect(() => {
    getPropertydetails();
}, [id]);
const scrollLeft = () => {
 
  if (cardListRef.current) {
    cardListRef.current.scrollBy({ left: -200, behavior: "smooth" });
  }
};





const handleNext = () => {
  const nextIndex = (selectedIndex + 1) % Propertydetails.length;
  updateMedia(nextIndex);
};

const handleBack = () => {
  const prevIndex =
    (selectedIndex - 1 + Propertydetails.length) % Propertydetails.length;
  updateMedia(prevIndex);
};

const updateMedia = (index) => {
  setSelectedIndex(index);
  const media = Propertydetails[index].file_path;
  setSelectedMedia(media);
  setIsVideo(Helpers.getUrlExtensions(media));
};
  return (
    <>
      {/* <div className="image_flex">
        {selectedMedia && isVideo ? (
          <video
            src={selectedMedia}
            controls
            style={{ width: "100%", height: "120%" }}
            autoPlay
          />
        ) : (
          <img
            src={selectedMedia ||noimage}
            alt="Selected media"
            style={{ width: "100%", height: "120%" }}
          />
        )}
      </div> */}
       <div className="image_flex" style={{ position: "relative" }}>
      {selectedMedia && isVideo ? (
        <video
          src={selectedMedia}
          controls
          style={{ width: "100%" }}
          autoPlay
        />
      ) : (
        <img
          src={selectedMedia || noimage}
          alt="Selected media"
          style={{ width: "100%",  }}
        />
      )}

      {/* Navigation Buttons */}
      {Propertydetails && Propertydetails.length > 0 && (
      <Stack
        direction="row"
        spacing={2}
        style={{
          position: "absolute",
          top: "115px",
          width: "100%",
          justifyContent: "space-between",
          padding: "0 10px",
          marginLeft: "-10px",
        }}
      >
        <div
          onClick={handleBack}
          className="Property_backbuttons"
          
        >
          {/* <FaChevronLeft /> */}
        </div>
        <div
          onClick={handleNext}
          className="Propright_buttons"
          
        >
          {/* <FaChevronRight /> */}
        </div>
      </Stack>
      )}
    </div>
     
    </>
  );
};

export default Propertyslide;
