import React, { useEffect, useState } from 'react'
import { getLoanApplicationFilterBasedOnProperty, getLoanApplicationsBasedOnProperty, getLoanApplicationsBasedOnVehicles } from '../../../../../../api/apiServices/customerPortalApis';
import { useDispatch } from 'react-redux'
import ResponseHandler from '../../../../../../services/ResponseHandler';
import { useNavigate, useParams } from 'react-router-dom';
import { Pagination, styled } from '@mui/material';
import CustomTableWithOutPagination from '../../../../../../components/CustomTableWithOutPagination/CustomTableWithOutPagination'
import CustomChip from '../../../../../../components/customChip/CustomChip';
import { LocalStorageServices } from '../../../../../../services/localStorageServices';
import KeyboardBackspaceIcon from "@mui/icons-material/KeyboardBackspace";
import CustomLoanApplicationFilter from '../CustomLoanApplicationFilter/CustomLoanApplicationFilter';
import { setRefresh } from '../../../../../../store/usersStore';
import { Button, Box } from "@mui/material";
import SimpleTable from '../../../../../../components/simpleTable/SimpleTable';

function PropertyLoanApplicationDetails() {
    const dispatch=useDispatch()
    const navigate=useNavigate()
    const { property_id } = useParams();
    const [PageNumber,SetPageNumber]=useState(1)
    const [pageCount,SetPageCount]=useState(0)
    const [PropertyLoanApplicationDetails, SetPropertyLoanApplicationDetails] = useState([]);
    const [isFiltered,setFiltered]=useState(false)
    const [values,setValues]=useState({
      "name": "",
      "email": "",
      "contact_number": "",
      "preferred_bank": "",
      "employer_status": "",
      "visit_required": ""
    })
    const hasAnyValue = (obj) => {
      return Object.values(obj).some(value => value !== "");
    };
    useEffect(()=>{
      if(hasAnyValue(values)){
        dispatch(getLoanApplicationFilterBasedOnProperty(property_id,PageNumber,10,values)).then((response)=>{
          const {loanApplications, count} = response
          SetPageCount(Math.ceil(count/10))
          SetPropertyLoanApplicationDetails(loanApplications);
      }).catch((error)=>{
        ResponseHandler(error)
      })
      }else{
        dispatch(getLoanApplicationsBasedOnProperty(property_id,PageNumber,10)).then((response)=>{
          const {loanApplications, count} = response
          SetPageCount(Math.ceil(count/10))
          SetPropertyLoanApplicationDetails(loanApplications);
      }).catch((error)=>{
        ResponseHandler(error)
      })
      }
     dispatch(setRefresh())
    },[PageNumber,isFiltered])
    
    const Columns = [
      {
        title: "#",
        dataIndex: "key",
        key: "key",
        
      },
      {
        title: "Name",
        dataIndex: "name",
        key: "name"
      },
      {
        title: "Email",
        dataIndex: "email",
        key: "email"
      },
      {
        title: "Contact Number",
        dataIndex: "contactNumber",
        key: "contactNumber"
      },
      {
        title: "Residential Address",
        dataIndex: "residentialAddress",
        key: "residentialAddress",
      },
      {
        title: "Message",
        dataIndex: "message",
        key: "message",
      },
      {
        title: "Employer Status",
        dataIndex: "employerStatus",
        key: "employerStatus",
      },
      {
        title: "Visit Required",
        dataIndex: "visitRequired",
        key: "visitRequired",
      },
      {
        title: "preferred Bank",
        dataIndex: "preferredBank",
        key: "preferredBank",
      },
      {
        title: "Loan Amount",
        dataIndex: "loanAmount",
        key: "loanAmount",
      },
      
    ];
    const Datas = () => {
      const result =
        PropertyLoanApplicationDetails &&
        PropertyLoanApplicationDetails.map((item, index) => {
          return {
            key: ((PageNumber*5)-(5-(index + 1))) ,
            id: item.la_id,
            id: item.la_id,
            propertyID:item.la_property_or_vehicle_id,
            name: item.la_name,
            email: item.la_email_address,
            contactNumber: item.la_contact_number,
            residentialAddress: item.la_residential_address,
            message:item.la_message	,
            employerStatus: item.la_employer_status===0?'Salaried':"Self Employed",
            visitRequired: item.la_visit_required===0?'No':"Yes",
            preferredBank: item.la_preferred_bank,
            loanAmount: item.la_loan_amount,
          };
        });
      return result;
    };
    
    
    const onchangeValues = async (event) => {
      const { value, name } = event.target;
      setValues({ ...values, [name]: value });
      
    };
    const onResetValues=()=>{
      setValues({
        "name": "",
        "email": "",
        "contact_number": "",
        "preferred_bank": "",
        "employer_status": "",
        "visit_required": ""
     })
     SetPageNumber(1)
     setFiltered(!isFiltered)
    }
  
    const onSearch=()=>{
      SetPageNumber(1)
      setFiltered(!isFiltered)
    }
   
  

    return (
      <div>
        <div  style={{display:'flex',justifyContent:'end',alignItems:'center',margin:'10px 0px'}}>
        <div
            className="back_button"
            onClick={() => {
              navigate(`/admin_portal/${LocalStorageServices.getUserRole()}/home/customer_portal/loan_applications?selected_loan_type=2`);
            }}
          >
            <KeyboardBackspaceIcon />
            <p>Back</p>
          </div>
        </div>
        <CustomLoanApplicationFilter values={values} onChange={onchangeValues} onReset={onResetValues} onSubmit={onSearch}/>
      <SimpleTable columns={Columns} rows={Datas()}/>
     
     
  </div>
    )
}

export default PropertyLoanApplicationDetails