import React from 'react'
import { Autocomplete, Button, TextField } from "@mui/material";
import './CustomLoanApplicationFilter.css'
import { required } from './HelperDropdown';
import { employeeStatus } from './HelperDropdown';

function CustomLoanApplicationFilter({values,onChange,onReset,onSubmit}) {
    const inputStyles = {
        "& .MuiOutlinedInput-root": {
          padding: "0px",
          fontSize: "15px !important",
          fontFamily: "Inter",
          width: 200,
          "& .MuiAutocomplete-input": {
            padding: "7.5px 4px 7.5px 9px !important",
            fontFamily: "Inter",
          },
          "& fieldset": {
            borderColor: "rgba(119, 182, 243, 1) !important", // Change border color
          },
          "&:hover fieldset": {
            borderColor: "rgba(119, 182, 243, 1) !important", // Change border color on hover
          },
          "&.Mui-focused fieldset": {
            borderColor: "rgba(119, 182, 243, 1) !important", // Change border color when focused
          },
        },
      };

      const ModifiedTextField = {
        '& .MuiOutlinedInput-root': {
          fontFamily: 'Inter, Arial, sans-serif !important', // Fallbacks for safety
          fontSize: '15px',
          '& fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
          '&:hover fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
        },
        '& .MuiInputBase-input': {
          fontFamily: 'Inter, Arial, sans-serif !important',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#272727 !important',
        },
      };

    return (
        <div style={{margin:'20px 0px'}}>
                <div className="CustomLoanApplicationFilter_input_action_container">
                    <TextField
                        name="name"
                        value={values.name}
                        fullWidth
                        className="CustomLoanApplicationFilter_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter name"}
                        onChange={onChange}
                        variant="outlined"
                        inputProps={{
                            style: {
                                padding: "7.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={ModifiedTextField}
                    />
                    <TextField
                        name="email"
                        value={values.email}
                        fullWidth
                        className="CustomLoanApplicationFilter_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter email"}
                        onChange={onChange}
                        inputProps={{
                            style: {
                                padding: "7.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={ModifiedTextField}
                    />
                    <TextField
                        name="contact_number"
                        value={values.contact_number}
                        fullWidth
                        className="CustomLoanApplicationFilter_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter contact number"}
                        onChange={onChange}
                        inputProps={{
                            style: {
                                padding: "7.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={ModifiedTextField}
                    />
                    <TextField
                        name="preferred_bank"
                        value={values.preferred_bank}
                        fullWidth
                        className="CustomLoanApplicationFilter_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter preferred bank"}
                        onChange={onChange}
                        inputProps={{
                            style: {
                                padding: "7.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={ModifiedTextField}
                    />
                    <Autocomplete
                            sx={{ ...inputStyles }}
                            name='employer_status'
                            id="combo-box-demo"
                            options={ employeeStatus }
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                let target={name:'employer_status',value:newValue?newValue.value:''}
                                onChange({target:target})
                              }}
                            value={
                                employeeStatus.find(
                                  (type) => type.value === values.employer_status
                                ) || null
                              }
                            ListboxProps={{
                            style: {
                                minHeight: 0,
                                fontSize: 15,
                                fontFamily: "Inter",
                                border: "0.5px solid rgba(119, 182, 243, 1) !important",
                            },
                            }}
                            menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                placeholder="Employer Status"
                                style={{ fontFamily: "Inter" }}
                            />
                            )}
                        />
                         <Autocomplete
                            sx={{ ...inputStyles }}
                            disablePortal
                            id="combo-box-demo"
                            options={required}
                            getOptionLabel={(option) => option.label}
                            onChange={(event, newValue) => {
                                let target={name:'visit_required',value:newValue?newValue.value:''}
                                onChange({target:target})
                              }}
                              value={
                                required.find(
                                  (type) => type.value === values.visit_required
                                ) || null
                              }
                            ListboxProps={{
                            style: {
                                minHeight: 0,
                                fontSize: 15,
                                fontFamily: "Inter",
                                border: "0.5px solid rgba(119, 182, 243, 1) !important",
                            },
                            }}
                            menuProps={{ style: { minHeight: 0, fontFamily: "Inter" } }}
                            renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                placeholder="Visit Required"
                                style={{ fontFamily: "Inter" }}
                            />
                            )}
                        />
                    <div className="CustomLoanApplicationFilter_action">
                        <Button
                            className="users_Search_button"
                            onClick={onSubmit}
                            variant="contained"
                            size="small"
                            style={{
                                textTransform: "none",
                                textTransform: "none",
                                fontFamily: "Inter",
                                fontSize: "15px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                padding: "8px 15px",
                                backgroundColor: "#0f62fe",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer"
                            }}
                        >
                            {"Search"}
                        </Button>
                        <Button
                            onClick={onReset}
                            className="users_Reset_button"
                            variant="outlined"
                            size="small"
                            style={{
                                textTransform: "none",
                                cursor: 'pointer',
                                fontFamily: 'inter',
                                border: '1px solid #005eb8',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                                padding: '7px 25px',
                                color: '#0f62fe',
                                fontSize: '14px'
                            }}

                        >
                            {"Reset"}
                        </Button>
                    </div>
                </div>
            </div>
    )
}

export default CustomLoanApplicationFilter