import React from 'react'
import { Button, TextField } from "@mui/material";
// import './CustomerFilterStyle.css'
import { useDispatch, useSelector } from 'react-redux';
import { setRefresh } from '../../../../../store/usersStore';
import { filterTestimonialsData,resetFilterTestimonialsData,setFilterTestimonialsDatas } from '../../../../../store/testimonialStore';
// import { setFilterDatas } from '../../../../../../store/usersStore';
// import { filterCustomerData, resetFilterCustomerData, setFilterCustomerDatas } from '../../../../../../store/customerStore';
function TestimonialFilter() {
    const dispatch=useDispatch()
    const TestimonialsData=useSelector((state)=>state.TestimonialStore.filterTestimonialsDetails)
    // ------------------------translation code--------------------------------------
  const translateText = async (text) => {
    const currentLang = localStorage.getItem("currentLanguage") || "en";
    if (currentLang === "es") {
      try {
        const response = await fetch(
          `https://api.mymemory.translated.net/get?q=${encodeURIComponent(text)}&langpair=es|en`
        );
        const data = await response.json();
        if(data.responseStatus==200){
            return data.responseData.translatedText;
        }else{
            return text; 
        }
       
      } catch (error) {
        console.error("Error translating text:", error);
        return text; // Fallback to original text if translation fails
      }
    }
    return text;
  };

    
    const UpdateFieldData = (event) => {
        const { name, value } = event.target;
        dispatch(setFilterTestimonialsDatas({ name, value }));
    };
    // const SearchData = () => {
        
    //     dispatch(filterTestimonialsData());
    //   };
      const SearchData = async () => {
        const currentLang = localStorage.getItem("currentLanguage") || "en";
        const isSpanish = currentLang === "es";
    
        if (isSpanish) {
          const translationPromises = Object.entries(TestimonialsData).map(([key, value]) => {
            return translateText(value).then((text) => {
              dispatch(setFilterTestimonialsDatas({ name: key, value: text }));
            });
          });
      
          await Promise.all(translationPromises);
        }
    
        dispatch(filterTestimonialsData());
        setTimeout(() => {
          dispatch(setRefresh());
        }, 500);
      };
      const ResetFilter = () => {
        // dispatch(resetFilterTestimonialsData());
        dispatch(resetFilterTestimonialsData());
        setTimeout(() => {
          dispatch(setRefresh());
        }, 500);
      };

      const ModifiedTextField = {
        '& .MuiOutlinedInput-root': {
          fontFamily: 'Inter, Arial, sans-serif !important', // Fallbacks for safety
          fontSize: '15px',
          '& fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
          '&:hover fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
        },
        '& .MuiInputBase-input': {
          fontFamily: 'Inter, Arial, sans-serif !important',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#272727 !important',
        },
      };
    return (
        <div>
                <div className="CustomerFilter_input_action_container">
                    <TextField
                        name="name"
                        value={TestimonialsData.name}
                        fullWidth
                        className="CustomerFilter_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter name"}
                        onChange={UpdateFieldData}
                        variant="outlined"
                        inputProps={{
                            style: {
                                padding: "7.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={ModifiedTextField}
                    />
                    <TextField
                        name="designation"
                        value={TestimonialsData.designation}
                        fullWidth
                        className="CustomerFilter_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter designation"}
                        onChange={UpdateFieldData}
                        inputProps={{
                            style: {
                                padding: "7.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={ModifiedTextField}
                    />
                    <TextField
                        name="rating"
                        value={TestimonialsData.rating}
                        fullWidth
                        className="CustomerFilter_input"
                        autoComplete="off"
                        style={{ fontSize: "15px" }}
                        size="small"
                        placeholder={"Enter rating"}
                        onChange={UpdateFieldData}
                        inputProps={{
                            style: {
                                padding: "7.5px 7px", // Add padding to input
                                fontSize: "15px",
                                fontWeight: 400,
                                fontFamily: "Inter",
                                borderRadius: "4px",
                            },
                        }}
                        sx={ModifiedTextField}
                    />
                    <div className="CustomerFilter_action">
                        <Button
                            className="users_Search_button"
                            onClick={SearchData}
                            variant="contained"
                            size="small"
                            style={{
                                textTransform: "none",
                                textTransform: "none",
                                fontFamily: "Inter",
                                fontSize: "15px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                lineHeight: "normal",
                                padding: "8px 15px",
                                backgroundColor: "#0f62fe",
                                color: "white",
                                border: "none",
                                borderRadius: "5px",
                                cursor: "pointer"
                            }}
                        >
                            {"Search"}
                        </Button>
                        <Button
                            onClick={ResetFilter}
                            className="users_Reset_button"
                            variant="outlined"
                            size="small"
                            style={{
                                textTransform: "none",
                                cursor: 'pointer',
                                fontFamily: 'inter',
                                border: '1px solid #005eb8',
                                backgroundColor: 'white',
                                borderRadius: '5px',
                                padding: '7px 25px',
                                color: '#0f62fe',
                                fontSize: '14px'
                            }}

                        >
                            {"Reset"}
                        </Button>
                    </div>
                </div>
            </div>
    )
}

export default TestimonialFilter