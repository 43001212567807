import React from 'react'
import { Button, TextField } from "@mui/material";
import './PropertyLoanFilterStyle.css'

function PropertyLoanFilter({values,onChange,onReset,onSubmit}) {
    const ModifiedTextField = {
        '& .MuiOutlinedInput-root': {
          fontFamily: 'Inter, Arial, sans-serif !important', // Fallbacks for safety
          fontSize: '15px',
          '& fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
          '&:hover fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
          '&.Mui-focused fieldset': {
            borderColor: 'rgba(119, 182, 243, 1) !important',
          },
        },
        '& .MuiInputBase-input': {
          fontFamily: 'Inter, Arial, sans-serif !important',
        },
        '& .MuiInputBase-input.Mui-disabled': {
          WebkitTextFillColor: '#272727 !important',
        },
      };
      
  return (
    <div>
    <div className="PropertyLoan_input_action_container">
        <TextField
            name="property_id"
            value={values.property_id}
            fullWidth
            className="PropertyLoan_input"
            autoComplete="off"
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter property id"}
            onChange={onChange}
            variant="outlined"
            inputProps={{
                style: {
                    padding: "7.5px 7px", // Add padding to input
                    fontSize: "15px",
                    fontWeight: 400,
                    fontFamily: "Inter",
                    borderRadius: "4px",
                },
            }}
            sx={ModifiedTextField}
        />
        <TextField
            name="title"
            value={values.title}
            fullWidth
            className="PropertyLoan_input"
            autoComplete="off"
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter title"}
            onChange={onChange}
            inputProps={{
                style: {
                    padding: "7.5px 7px", // Add padding to input
                    fontSize: "15px",
                    fontWeight: 400,
                    fontFamily: "Inter",
                    borderRadius: "4px",
                },
            }}
            sx={ModifiedTextField}
        />
        <TextField
            name="property_type"
            value={values.property_type}
            fullWidth
            className="PropertyLoan_input"
            autoComplete="off"
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter property type"}
            onChange={onChange}
            inputProps={{
                style: {
                    padding: "7.5px 7px", // Add padding to input
                    fontSize: "15px",
                    fontWeight: 400,
                    fontFamily: "Inter",
                    borderRadius: "4px",
                },
            }}
            sx={ModifiedTextField}
        />
        <TextField
            name="location"
            value={values.location}
            fullWidth
            className="PropertyLoan_input"
            autoComplete="off"
            style={{ fontSize: "15px" }}
            size="small"
            placeholder={"Enter location"}
            onChange={onChange}
            inputProps={{
                style: {
                    padding: "7.5px 7px", // Add padding to input
                    fontSize: "15px",
                    fontWeight: 400,
                    fontFamily: "Inter",
                    borderRadius: "4px",
                },
            }}
            sx={ModifiedTextField}
        />
       
        <div className="PropertyLoan_action">
            <Button
                className="users_Search_button"
                onClick={onSubmit}
                variant="contained"
                size="small"
                style={{
                    textTransform: "none",
                    textTransform: "none",
                    fontFamily: "Inter",
                    fontSize: "15px",
                    fontStyle: "normal",
                    fontWeight: 600,
                    lineHeight: "normal",
                    padding: "8px 15px",
                    backgroundColor: "#0f62fe",
                    color: "white",
                    border: "none",
                    borderRadius: "5px",
                    cursor: "pointer"
                }}
            >
                {"Search"}
            </Button>
            <Button
                onClick={onReset}
                className="users_Reset_button"
                variant="outlined"
                size="small"
                style={{
                    textTransform: "none",
                    cursor: 'pointer',
                    fontFamily: 'inter',
                    border: '1px solid #005eb8',
                    backgroundColor: 'white',
                    borderRadius: '5px',
                    padding: '7px 25px',
                    color: '#0f62fe',
                    fontSize: '14px'
                }}

            >
                {"Reset"}
            </Button>
        </div>
    </div>
</div>
  )
}

export default PropertyLoanFilter